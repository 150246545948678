import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
  },
  actions: {
    get({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/web/application/eCommerce/termsAndPrivacy/get')
          .then(response => {
            commit('GET', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/termsAndPrivacy/update', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
