<template>
  <b-card
    class="blog-edit-wrapper"
  >
    <b-row>
      <b-col md="1">
        <b-form-group
          :label="$t('Open Privacy')"
          label-for="register-action"
        >
          <b-form-checkbox
          v-model="
              openPrivacy
            "
            class="custom-control-primary ml-2"
            name="check-button"
            switch
          />
        </b-form-group>
      </b-col>
      <b-col md="11">
        <b-form-group
          :label="$t('Action')"
          label-for="register-action"
        >
          <v-select
            id="register-action"
            v-model="action"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="OptionAction"
            :reduce="(OptionAction) => OptionAction.value"
            class="select-size-lg"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="action === 'link'"
        cols="4"
      >
        <b-form-group
          :label="$t('Terms and Conditions Link')"
          label-for="register-termsConditionLink"
        >
          <b-form-input
            id="register-termsConditionLink"
            v-model="termsConditionLink"
            name="register-termsConditionLink"
            :placeholder="$t('Terms and Conditions Link')"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="action === 'link'"
        cols="4"
      >
        <b-form-group
          :label="$t('Terms Link')"
          label-for="register-termsLink"
        >
          <b-form-input
            id="register-termsLink"
            v-model="termsLink"
            name="register-termsLink"
            :placeholder="$t('Terms Link')"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="action === 'link'"
        cols="4"
      >
        <b-form-group
          :label="$t('Privacy Link')"
          label-for="register-privacyLink"
        >
          <b-form-input
            id="register-privacyLink"
            v-model="privacyLink"
            name="register-privacyLink"
            :placeholder="$t('Privacy Link')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="action === 'html'">
      <b-col cols="12">
        <b-tabs v-model="tabIndex">
          <b-tab
            v-for="(itemLang, indexLang) in language"
            :key="indexLang"
            :title="$t(itemLang.name)"
          >

            <b-row>
              <b-col
                cols="12"
                class=""
              >
                <h1>
                  1. {{ $t('Terms and Conditions of Use') }}
                </h1>
              </b-col>
              <b-col
                cols="6"
                class="mb-4"
              >

                <div class="code">
                  <div class="termsCondition-code">
                    <b-tabs>
                      <b-tab :title="'Editor'">
                        <h1>Editor</h1>

                        <vue-editor
                          v-if="termsCondition[indexLang]"
                          :id="`termsCondition_editor${itemLang.lang}`"
                          :ref="`termsCondition_editor_${itemLang.lang}`"
                          :value="termsCondition[indexLang]['value']"
                          :editor-toolbar="customToolbar"
                          @input="
                            inputLanguage(
                              $event,
                              itemLang.lang,
                              indexLang,
                              'termsConditionCode',
                            )
                          "
                        />
                      </b-tab>
                      <b-tab :title="'HTML'">
                        <h1>HTML</h1>
                        <b-form-textarea
                          v-if="termsConditionCode[indexLang]"

                          :value="termsConditionCode[indexLang]['value']"

                          @input="
                            inputLanguage(
                              $event,
                              itemLang.lang,
                              indexLang,
                              'termsConditionCode',
                            )
                          "
                        />
                      </b-tab>
                    </b-tabs>

                  </div>
                  <div class="css-code">
                    <h1>CSS</h1>
                    <b-form-textarea
                      v-if="termsConditionCSS[indexLang]"
                      :value="termsConditionCSS[indexLang]['value']"

                      @input="
                        inputLanguage(
                          $event,
                          itemLang.lang,
                          indexLang,
                          'termsConditionCSS',
                        )
                      "
                    />
                  </div>
                </div>

              </b-col>
              <b-col
                cols="6"
                class="mb-4"
              >
                <h1>{{ $t('Example') }}</h1>
                <iframe
                  :id="`termsConditionRender_${itemLang.lang}`"
                  style="width: 100%;
                          height: 100%;
                          border: none;
                          border-radius:4px;
                          border:solid 0.5px #999;
                          background-color: white;
                          "
                />
              </b-col>
              <b-col
                cols="12"
                class=""
              >
                <h1>
                  2. {{ $t('Terms of Use') }}
                </h1>
              </b-col>
              <b-col
                cols="6"
                class="mb-4"
              >

                <div class="code">
                  <div class="terms-code">
                    <b-tabs>
                      <b-tab :title="'Editor'">
                        <h1>Editor</h1>

                        <vue-editor
                          v-if="terms[indexLang]"
                          :id="`terms_editor${itemLang.lang}`"
                          :ref="`terms_editor_${itemLang.lang}`"
                          :value="terms[indexLang]['value']"
                          :editor-toolbar="customToolbar"
                          @input="
                            inputLanguage(
                              $event,
                              itemLang.lang,
                              indexLang,
                              'termsCode',
                            )
                          "
                        />
                      </b-tab>
                      <b-tab :title="'HTML'">
                        <h1>HTML</h1>
                        <b-form-textarea
                          v-if="termsCode[indexLang]"

                          :value="termsCode[indexLang]['value']"

                          @input="
                            inputLanguage(
                              $event,
                              itemLang.lang,
                              indexLang,
                              'termsCode',
                            )
                          "
                        />
                      </b-tab>
                    </b-tabs>

                  </div>
                  <div class="css-code">
                    <h1>CSS</h1>
                    <b-form-textarea
                      v-if="termsCSS[indexLang]"
                      :value="termsCSS[indexLang]['value']"

                      @input="
                        inputLanguage(
                          $event,
                          itemLang.lang,
                          indexLang,
                          'termsCSS',
                        )
                      "
                    />
                  </div>
                </div>

              </b-col>
              <b-col
                cols="6"
                class="mb-4"
              >
                <h1>{{ $t('Example') }}</h1>
                <iframe
                  :id="`termsRender_${itemLang.lang}`"
                  style="width: 100%;
                          height: 100%;
                          border: none;
                          border-radius:4px;
                          border:solid 0.5px #999;
                          background-color: white;
                          "
                />
              </b-col>
              <b-col
                cols="12"
                class=""
              >
                <h1>
                  3. {{ $t('Privacy') }}
                </h1>
              </b-col>
              <b-col
                cols="6"
                class="mb-4"
              >

                <div class="code">
                  <div class="privacy-code">
                    <b-tabs>
                      <b-tab :title="'Editor'">
                        <h1>Editor</h1>

                        <vue-editor
                          v-if="privacy[indexLang]"
                          :id="`privacy_editor${itemLang.lang}`"
                          :ref="`privacy_editor_${itemLang.lang}`"
                          :value="privacy[indexLang]['value']"
                          :editor-toolbar="customToolbar"
                          @input="
                            inputLanguage(
                              $event,
                              itemLang.lang,
                              indexLang,
                              'privacyCode',
                            )
                          "
                        />
                      </b-tab>
                      <b-tab :title="'HTML'">
                        <h1>HTML</h1>
                        <b-form-textarea
                          v-if="privacyCode[indexLang]"

                          :value="privacyCode[indexLang]['value']"

                          @input="
                            inputLanguage(
                              $event,
                              itemLang.lang,
                              indexLang,
                              'privacyCode',
                            )
                          "
                        />
                      </b-tab>
                    </b-tabs>

                  </div>
                  <div class="css-code">
                    <h1>CSS</h1>
                    <b-form-textarea
                      v-if="privacyCSS[indexLang]"
                      :value="privacyCSS[indexLang]['value']"

                      @input="
                        inputLanguage(
                          $event,
                          itemLang.lang,
                          indexLang,
                          'privacyCSS',
                        )
                      "
                    />
                  </div>
                </div>

              </b-col>
              <b-col
                cols="6"
                class="mb-4"
              >
                <h1>{{ $t('Example') }}</h1>
                <iframe
                  :id="`privacyRender_${itemLang.lang}`"
                  style="width: 100%;
                          height: 100%;
                          border: none;
                          border-radius:4px;
                          border:solid 0.5px #999;
                          background-color: white;
                          "
                />
              </b-col>

            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="mt-50"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="saveChanges"
        >
          {{ $t('Save Changes') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          @click="cancelBtn"
        >
          {{ $t('Cancel') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormCheckbox,
  BTabs,
  BTab,
  BFormTextarea, BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import StoreModule from '@/store/services/eCommerce/termsAndPrivacy'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
// eslint-disable-next-line import/no-unresolved

import { VueEditor } from 'vue2-editor'

const STORE_MODULE_NAME = 'termsAndPrivacy'

export default {
  components: {
    BFormCheckbox,
    VueEditor,

    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BTabs,
    BTab,
    // quillEditor,
    // Editor,
    BFormTextarea,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BMediaAside,
    // eslint-disable-next-line vue/no-unused-components
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      openPrivacy: false,
      action: 'link',
      tabIndex: 0,
      termsConditionLink: '',
      termsCondition: [],
      termsConditionCode: [],
      termsConditionCSS: [],
      termsLink: '',
      terms: [],
      termsCode: [],
      termsCSS: [],
      privacyLink: '',
      privacy: [],
      privacyCode: [],
      privacyCSS: [],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // [
        //   { align: '' },
        //   { align: 'center' },
        //   { align: 'right' },
        //   { align: 'justify' },
        // ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link', 'image', 'video'],
        ['clean'], // remove formatting button
      ],

    }
  },
  computed: {
    OptionAction() {
      return [
        { name: this.$t('Link'), value: 'link' },
        { name: this.$t('HTML'), value: 'html' },
      ]
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
  },
  watch: {
    tabIndex(val) {
      if (this.termsCode.length > 0 && this.termsCSS.length > 0) {
        this.renderCode(val)
      }
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }

    store
      .dispatch(`${STORE_MODULE_NAME}/get`, { })
      .then(result => {
        if (result.data.data) {
          // renderBy
          const {
            openPrivacy, action, termsConditionLink, termsCondition, termsConditionCode, termsConditionCSS, termsLink, terms, termsCode, termsCSS, privacyLink, privacy, privacyCode, privacyCSS,
          } = result.data.data
          this.openPrivacy = openPrivacy
          this.action = action
          this.termsConditionLink = termsConditionLink
          this.termsCondition = termsCondition
          this.termsConditionCode = termsConditionCode
          this.termsConditionCSS = termsConditionCSS

          this.termsLink = termsLink
          this.terms = terms
          this.termsCode = termsCode
          this.termsCSS = termsCSS

          this.privacyLink = privacyLink
          this.privacy = privacy
          this.privacyCode = privacyCode
          this.privacyCSS = privacyCSS

          if (this.termsConditionCode.length < 1) {
            this.termsConditionCode = [{
              lang: 'th',
              value: '',
            },
            {
              lang: 'en',
              value: '',
            },
            ]
            this.termsConditionCode = this.terms

            if (this.termsConditionCode.length !== this.language.length) {
              for (let index = 0; index < this.language.length; index += 1) {
                const indexLanguage = this.termsConditionCode.findIndex(element => element.lang === this.language[index].lang)
                if (indexLanguage < 0) {
                  this.termsConditionCode.push({
                    lang: this.language[index].lang, // th , en
                    value: '',
                  })
                }
              }
              for (let index = 0; index < this.termsConditionCode.length; index += 1) {
                const indexLanguage = this.language.findIndex(element => element.lang === this.termsConditionCode[index].lang)
                if (indexLanguage < 0) {
                  this.termsConditionCode.splice(index, 1)
                }
              }
            }
          }
          if (this.termsConditionCSS.length < 1) {
            this.termsConditionCSS = [{
              lang: 'th',
              value: '',
            },
            {
              lang: 'en',
              value: '',
            },
            ]
            if (this.termsConditionCSS.length !== this.language.length) {
              for (let index = 0; index < this.language.length; index += 1) {
                const indexLanguage = this.termsConditionCSS.findIndex(element => element.lang === this.language[index].lang)
                if (indexLanguage < 0) {
                  this.termsConditionCSS.push({
                    lang: this.language[index].lang, // th , en
                    value: '',
                  })
                }
              }
              for (let index = 0; index < this.termsConditionCSS.length; index += 1) {
                const indexLanguage = this.language.findIndex(element => element.lang === this.termsConditionCSS[index].lang)
                if (indexLanguage < 0) {
                  this.termsConditionCSS.splice(index, 1)
                }
              }
            }
          }

          if (this.termsCode.length < 1) {
            this.termsCode = [{
              lang: 'th',
              value: '',
            },
            {
              lang: 'en',
              value: '',
            },
            ]
            this.termsCode = this.terms

            if (this.termsCode.length !== this.language.length) {
              for (let index = 0; index < this.language.length; index += 1) {
                const indexLanguage = this.termsCode.findIndex(element => element.lang === this.language[index].lang)
                if (indexLanguage < 0) {
                  this.termsCode.push({
                    lang: this.language[index].lang, // th , en
                    value: '',
                  })
                }
              }
              for (let index = 0; index < this.termsCode.length; index += 1) {
                const indexLanguage = this.language.findIndex(element => element.lang === this.termsCode[index].lang)
                if (indexLanguage < 0) {
                  this.termsCode.splice(index, 1)
                }
              }
            }
          }
          if (this.termsCSS.length < 1) {
            this.termsCSS = [{
              lang: 'th',
              value: '',
            },
            {
              lang: 'en',
              value: '',
            },
            ]
            if (this.termsCSS.length !== this.language.length) {
              for (let index = 0; index < this.language.length; index += 1) {
                const indexLanguage = this.termsCSS.findIndex(element => element.lang === this.language[index].lang)
                if (indexLanguage < 0) {
                  this.termsCSS.push({
                    lang: this.language[index].lang, // th , en
                    value: '',
                  })
                }
              }
              for (let index = 0; index < this.termsCSS.length; index += 1) {
                const indexLanguage = this.language.findIndex(element => element.lang === this.termsCSS[index].lang)
                if (indexLanguage < 0) {
                  this.termsCSS.splice(index, 1)
                }
              }
            }
          }

          if (this.privacyCode.length < 1) {
            this.privacyCode = [{
              lang: 'th',
              value: '',
            },
            {
              lang: 'en',
              value: '',
            },
            ]
            this.privacyCode = this.privacy

            if (this.privacyCode.length !== this.language.length) {
              for (let index = 0; index < this.language.length; index += 1) {
                const indexLanguage = this.privacyCode.findIndex(element => element.lang === this.language[index].lang)
                if (indexLanguage < 0) {
                  this.privacyCode.push({
                    lang: this.language[index].lang, // th , en
                    value: '',
                  })
                }
              }
              for (let index = 0; index < this.privacyCode.length; index += 1) {
                const indexLanguage = this.language.findIndex(element => element.lang === this.privacyCode[index].lang)
                if (indexLanguage < 0) {
                  this.privacyCode.splice(index, 1)
                }
              }
            }
          }
          if (this.privacyCSS.length < 1) {
            this.privacyCSS = [{
              lang: 'th',
              value: '',
            },
            {
              lang: 'en',
              value: '',
            },
            ]
            if (this.privacyCSS.length !== this.language.length) {
              for (let index = 0; index < this.language.length; index += 1) {
                const indexLanguage = this.privacyCSS.findIndex(element => element.lang === this.language[index].lang)
                if (indexLanguage < 0) {
                  this.privacyCSS.push({
                    lang: this.language[index].lang, // th , en
                    value: '',
                  })
                }
              }
              for (let index = 0; index < this.privacyCSS.length; index += 1) {
                const indexLanguage = this.language.findIndex(element => element.lang === this.privacyCSS[index].lang)
                if (indexLanguage < 0) {
                  this.privacyCSS.splice(index, 1)
                }
              }
            }
          }

          const indexRender = this.language.findIndex(e => e.lang === this.$i18n.locale)
          this.renderCode(indexRender)

          if (this.termsCondition.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.termsCondition.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.termsCondition.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.termsCondition.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.termsCondition[index].lang)
              if (indexLanguage < 0) {
                this.termsCondition.splice(index, 1)
              }
            }
          }

          if (this.terms.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.terms.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.terms.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.terms.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.terms[index].lang)
              if (indexLanguage < 0) {
                this.terms.splice(index, 1)
              }
            }
          }

          if (this.privacy.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.privacy.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.privacy.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.privacy.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.privacy[index].lang)
              if (indexLanguage < 0) {
                this.privacy.splice(index, 1)
              }
            }
          }
        }
      })
      .catch(error => {
        if (this.termsCondition.length !== this.language.length) {
          for (let index = 0; index < this.language.length; index += 1) {
            const indexLanguage = this.termsCondition.findIndex(element => element.lang === this.language[index].lang)
            if (indexLanguage < 0) {
              this.termsCondition.push({
                lang: this.language[index].lang, // th , en
                value: '',
              })
            }
          }
        }
        if (this.termsConditionCode.length !== this.language.length) {
          for (let index = 0; index < this.language.length; index += 1) {
            const indexLanguage = this.termsConditionCode.findIndex(element => element.lang === this.language[index].lang)
            if (indexLanguage < 0) {
              this.termsConditionCode.push({
                lang: this.language[index].lang, // th , en
                value: '',
              })
            }
          }
        }
        if (this.termsConditionCSS.length !== this.language.length) {
          for (let index = 0; index < this.language.length; index += 1) {
            const indexLanguage = this.termsConditionCSS.findIndex(element => element.lang === this.language[index].lang)
            if (indexLanguage < 0) {
              this.termsConditionCSS.push({
                lang: this.language[index].lang, // th , en
                value: '',
              })
            }
          }
        }

        if (this.terms.length !== this.language.length) {
          for (let index = 0; index < this.language.length; index += 1) {
            const indexLanguage = this.terms.findIndex(element => element.lang === this.language[index].lang)
            if (indexLanguage < 0) {
              this.terms.push({
                lang: this.language[index].lang, // th , en
                value: '',
              })
            }
          }
        }
        if (this.termsCode.length !== this.language.length) {
          for (let index = 0; index < this.language.length; index += 1) {
            const indexLanguage = this.termsCode.findIndex(element => element.lang === this.language[index].lang)
            if (indexLanguage < 0) {
              this.termsCode.push({
                lang: this.language[index].lang, // th , en
                value: '',
              })
            }
          }
        }
        if (this.termsCSS.length !== this.language.length) {
          for (let index = 0; index < this.language.length; index += 1) {
            const indexLanguage = this.termsCSS.findIndex(element => element.lang === this.language[index].lang)
            if (indexLanguage < 0) {
              this.termsCSS.push({
                lang: this.language[index].lang, // th , en
                value: '',
              })
            }
          }
        }

        if (this.privacy.length !== this.language.length) {
          for (let index = 0; index < this.language.length; index += 1) {
            const indexLanguage = this.privacy.findIndex(element => element.lang === this.language[index].lang)
            if (indexLanguage < 0) {
              this.privacy.push({
                lang: this.language[index].lang, // th , en
                value: '',
              })
            }
          }
        }
        if (this.privacyCode.length !== this.language.length) {
          for (let index = 0; index < this.language.length; index += 1) {
            const indexLanguage = this.privacyCode.findIndex(element => element.lang === this.language[index].lang)
            if (indexLanguage < 0) {
              this.privacyCode.push({
                lang: this.language[index].lang, // th , en
                value: '',
              })
            }
          }
        }
        if (this.privacyCSS.length !== this.language.length) {
          for (let index = 0; index < this.language.length; index += 1) {
            const indexLanguage = this.privacyCSS.findIndex(element => element.lang === this.language[index].lang)
            if (indexLanguage < 0) {
              this.privacyCSS.push({
                lang: this.language[index].lang, // th , en
                value: '',
              })
            }
          }
        }
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    renderCode(indexLang) {
      const locale = this.language[indexLang].lang
      const termsConditionRender = document.querySelector(`#termsConditionRender_${locale}`)

      const termsConditionCode = this.termsConditionCode[indexLang].value
      const termsConditionCSS = this.termsConditionCSS[indexLang].value

      termsConditionRender.contentDocument.body.innerHTML = `<style>${termsConditionCSS}</style>${termsConditionCode}`

      const termsRender = document.querySelector(`#termsRender_${locale}`)

      const termsCode = this.termsCode[indexLang].value
      const termsCSS = this.termsCSS[indexLang].value

      termsRender.contentDocument.body.innerHTML = `<style>${termsCSS}</style>${termsCode}`

      const privacyRender = document.querySelector(`#privacyRender_${locale}`)

      const privacyCode = this.privacyCode[indexLang].value
      const privacyCSS = this.privacyCSS[indexLang].value

      privacyRender.contentDocument.body.innerHTML = `<style>${privacyCSS}</style>${privacyCode}`
    },
    inputLanguage(input, lang, index, type) {
      console.log(input, lang, index, type)
      if (type === 'termsConditionCode') {
        if (this.termsConditionCode[index].lang === lang) {
          this.termsConditionCode[index].value = input
          this.renderCode(index)
        }
      } else if (type === 'termsConditionCSS') {
        if (this.termsConditionCSS[index].lang === lang) {
          this.termsConditionCSS[index].value = input
          this.renderCode(index)
        }
      }

      if (type === 'termsCode') {
        if (this.termsCode[index].lang === lang) {
          this.termsCode[index].value = input
          this.renderCode(index)
        }
      } else if (type === 'termsCSS') {
        if (this.termsCSS[index].lang === lang) {
          this.termsCSS[index].value = input
          this.renderCode(index)
        }
      }

      if (type === 'privacyCode') {
        if (this.privacyCode[index].lang === lang) {
          this.privacyCode[index].value = input
          this.renderCode(index)
        }
      } else if (type === 'privacyCSS') {
        if (this.privacyCSS[index].lang === lang) {
          this.privacyCSS[index].value = input
          this.renderCode(index)
        }
      }
      console.log(this.name)
    },
    saveChanges() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            const dataFormat = [{
              lang: 'th',
              value: '',
            },
            {
              lang: 'en',
              value: '',
            }]
            const obj = {
              openPrivacy: this.openPrivacy,
              action: this.action,
              termsConditionLink: this.termsConditionLink,
              termsCondition: dataFormat.map((e, index) => ({
                lang: e.lang,
                value: `<style>${this.termsConditionCSS[index].value}</style>${this.termsConditionCode[index].value}`,
              })),
              termsConditionCode: this.termsConditionCode,
              termsConditionCSS: this.termsConditionCSS,

              termsLink: this.termsLink,
              terms: dataFormat.map((e, index) => ({
                lang: e.lang,
                value: `<style>${this.termsCSS[index].value}</style>${this.termsCode[index].value}`,
              })),
              termsCode: this.termsCode,
              termsCSS: this.termsCSS,

              privacyLink: this.privacyLink,
              privacy: dataFormat.map((e, index) => ({
                lang: e.lang,
                value: `<style>${this.privacyCSS[index].value}</style>${this.privacyCode[index].value}`,
              })),
              privacyCode: this.privacyCode,
              privacyCSS: this.privacyCSS,
            }

            store
              .dispatch(`${STORE_MODULE_NAME}/update`, obj)
              .then(result => {
                this.show = false
              }).catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    cancelBtn() {
      this.$router.go(-1)
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/quill.scss';
  @import '@core/scss/vue/pages/page-blog.scss';

   .ql-container{
    height: 250px;
  }

  </style>
